import 'babel-polyfill'
import 'bootstrap'
import Glide from '@glidejs/glide'

// Styling
import '../scss/style.scss'
// import DotLottiePlayer from "@dotlottie/player-component";

// Fontawesome
// import { dom, library } from '@fortawesome/fontawesome-svg-core'
// import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
// library.add(faFacebookF, faTwitter, faInstagram, faLinkedinIn)
// dom.watch()
jQuery(document).ready(function($) {
    
    function changeLogoNav(e){
        
        if(e.currentTarget.ariaExpanded==="true")
        {
            $(".navbar-brand").addClass("dark")
        }else{
            $(".navbar-brand").removeClass("dark")
        }
        
    }

    $('.navbar-toggler').on('click', changeLogoNav)
    if($('.login.wp-core-ui').length){
        $('#user_login').attr('placeholder', 'Gebruikersnaam of e-mailadres');
        $('#user_pass').attr('placeholder', 'Wachtwoord')
    }

    fadeIn();
    let darkMenu = $('.navbar').is('.navbar-dark');
    $(document).scroll(function() {
        fadeIn();

        
        if ($(window).scrollTop() > 10) {
            $('nav').addClass('scrolled');
            if (darkMenu) {
                $('.navbar').removeClass('navbar-dark').addClass('navbar-light');
            }
        } else {
            $('nav').removeClass('scrolled');
            if (darkMenu) {
                $('.navbar').removeClass('navbar-light').addClass('navbar-dark'); 
            }
        }
    });

    // Front page
    if ($('.front-page__block2-image').length) {
        $('.front-page__block2-image').css('left', $('.faq-block').outerWidth() * 0.4166 + $('.faq-block').offset().left);
        $(window).resize(function() {
            $('.front-page__block2-image').css('left', $('.faq-block').outerWidth() * 0.4166 + $('.faq-block').offset().left);
        });
    }

    if ($('.front-page__header .glide').length && $(window).innerWidth() > 992) {
        let slideCount = $('.glide__slide').length;
        let progress = $('.front-page__header-progress');
        let glide = new Glide(".glide", {
            type: "carousel",
            perView: 1,
            focusAt: "center",
            startAt: 0,
            gap: 0
        });

        glide.on("mount.before", (item) => {
            progress.text((pad(glide.index + 1)) + " / " + pad(slideCount))
        });
        glide.on("move.after", () => {
            progress.text((pad(glide.index + 1)) + " / " + pad(slideCount))
        });
        glide.mount();
        
        function pad(d) {
            return (d < 10) ? '0' + d.toString() : d.toString();
        }
    }

    
    // FAQ
    $('li[data-term]').click(function() {
        let target;
        if ($(this).is('h3')) {
            target = $(this).parent();
        } else {
            target = $(this);
        }
        openCategory($, target.data('term'));
    });

    $('.faq__question').click(function() {
        $(this).toggleClass('active');
    })

    filterFaq($, $('.faq__search').val());
    $('.faq__search').on('input change', function() {
        filterFaq($, $(this).val());
    })

    // Quotation
    if ($('.quotation__model.active').length) setModelActive($, $('.quotation__model.active').data('id'));
    $('.quotation__model').click(function() {
        setModelActive($, $(this).data('id'));
    });

    $('.quotation__option').click(function(e) {
        if ($(e.target).is('input')) return;

        // Toggle checkbox on click
        let input = $(this).find('input');
        input.prop('checked', !input.prop('checked'));
        updateQuotationOptions($);
    });

    $('.quotation__option input').change(function() {
        updateQuotationOptions($);
    });

    $('.quotation__length').on('input change', function() {
        $('[name="kitchen-length"]').val($(this).val());
        updateLengthDepth($);
    });
    $('.quotation__depth').on('input change', function(e) {
        $('[name="kitchen-depth"]').val($(this).val());
        updateLengthDepth($); 
    });
    
    // $(".quotation__model-info").on("click",function(){
    // })
    $(".quotation__length , .quotation__depth").on("input change",function(){
        let warning = $(".priceWarning")
        let count =0;
        $(".quotation__length , .quotation__depth").each(function( index ) {
            if($(this).val() != $(this).data("size")){
                count+=(count>1?0:1);
                warning.css({display:"block"})
            }     
        })
        if(count==0){
            warning.css({display:"none"})
        }
       
    })

    if ($('.quotation__contact').length) {
        $(window).scroll(function() {
            $('.quotation__contact')
                .css('position', 'relative')
                .css('top', '0')
                .css('left', '0');
            
            let contactTop = $('.quotation__contact').offset().top;
            let contactLeft = $('.quotation__contact').offset().left;
            let contactWidth = $('.quotation__contact').outerWidth();
            let windowTop = $(window).scrollTop();
            if (windowTop + 80 > contactTop) {
                $('.quotation__contact')
                    .css('position', 'fixed')
                    .css('top', '80px')
                    .css('left', contactLeft)
                    .css('width', contactWidth);
            }
        })
    }
    let lottieUsps = document.querySelectorAll(".front-page__usps .lottie-player").forEach((x,i)=>{
        x.addEventListener("ready",e=>{
            let delay=0.5;
            if(i==2){
                delay=2
            }else if(i==0){
                delay=1
            }
            setTimeout(()=>{
                x.play()
            },delay*1000)
        })
        x.addEventListener("complete",()=>{
            setTimeout(()=>{
                x.seek("0%")
                x.play()
            },3000)
        })
    })
});

function fadeIn() {
    let $ = jQuery;
    $('.fade-in:not(.faded-in)').each(function() {
        let top = $(this).offset().top;
        let bottom = $(this).offset().top + $(this).outerHeight();
        let windowTop = $(window).scrollTop();
        let windowBottom = $(window).scrollTop() + $(window).innerHeight();

        if ((windowBottom - 100 > top) && (windowTop + 100 < bottom)){
            $(this).addClass('faded-in');
            setTimeout(fadeIn, 100);
            return false;
        }
    })
}

// FAQ
function openCategory($, slug) {
    $(document).scrollTop($('.faq__question-category[data-term="' + slug + '"]').offset().top - 120);
}

function filterFaq($, query) {
    if (query && query.length > 0) {
        $('[data-term], .faq__question').addClass('d-none');
        query = query.toLowerCase().split(' ');
        $('.faq__question-category[data-term]').each(function() {
            let term = $(this).text().toLowerCase();
            if (query.every(function(q) {
                return term.indexOf(q) > -1;
            })) {
                $('[data-term="' + $(this).data('term') + '"]').removeClass('d-none');
            }
        });

        $('.faq__question').each(function() {
            let term = $(this).text().toLowerCase();
            if (query.every(function(q) {
                return term.indexOf(q) > -1;
            })) {
                $(this).removeClass('d-none');
            }
        });
    } else {
        $('[data-term], .faq__question').removeClass('d-none');
    }
}
let hashLoaded=false;
function setModelActive($, model) {
    $('.quotation__model').removeClass('active');
    $('.quotation__model[data-id="'+model+'"]').addClass('active');
    $('[data-show-id]').addClass('d-none');
    $('[data-show-id="'+model+'"]').removeClass('d-none');
    $('.quotation__step[data-step="1"]').addClass('completed');
    $('.quotation__step[data-step="2"]').removeClass('disabled');
    $('.quotation__step[data-step="3"]').removeClass('disabled');
    $('.quotation__step[data-step="4"]').removeClass('disabled');
    $('[name="kitchen-model"]').val($('.quotation__model.active .quotation__model-title').text().trim());
    updateQuotationOptions($);
    let sizes= $('.quotation__model.active').data("sizes")
    $(".quotation__length").attr("placeholder","Standaard "+sizes.lengte+"cm").data("size",sizes.lengte).val(sizes.lengte)
    $(".quotation__depth").attr("placeholder","Standaard "+sizes.breedte+"cm").data("size",sizes.breedte).val(sizes.breedte)
    $('[name="kitchen-length"]').val(sizes.lengte);
    $('[name="kitchen-depth"]').val(sizes.breedte);
    if(window.location.hash || !hashLoaded){
        hashLoaded=true;
        $('html,body').animate({scrollTop: $('.quotation__step[data-step="1"]').offset().top - 120}, 300);
        return;
    }
    $('html,body').animate({scrollTop: $('.quotation__step[data-step="2"]').offset().top - 120}, 300);
}

function updateQuotationOptions($) {
    let options = $('.quotation__options:not(.d-none) input:checked').map(function() { 
        return $(this).parent().data("name").trim();
    }).get();
    $('[name="kitchen-options"]').val(null);
    if (options.length > 0) {
        $('[data-step="2"]').addClass('completed');
        $('[name="kitchen-options"]').val("  -  "+options.join('\r\n  -  '));
    }
}

function updateLengthDepth($) {
    if ($('.quotation__length').val() || $('.quotation__depth').val()) {
        $('[data-step="2"]').addClass('completed');
    }

    if ($('.quotation__length').val() && $('.quotation__depth').val()) {
        $('[data-step="3"]').addClass('completed');
        $('[data-step="4"]').removeClass('disabled');
    } else {
        $('[data-step="3"]').removeClass('completed');
        $('[data-step="4"]').addClass('disabled');
    }
}

